.modal-logo {
    text-align: center;
    display: flex;
    justify-content: center;
}

.modal-logo a:focus-visible {
    outline: none;
}
.form_input {
    width: 100%;
    padding: 12px;
    border: 1px solid #8F8F9D;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000;
}
.form_input::placeholder {
    color: #8F8F9D;
}
.form_input:focus-visible {
    outline: none;
}
.form_input:focus {
    box-shadow: none;
    outline: none;
}
.radiobtn_wrap {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.registration_form .gender_radio_btn .radiobtn_wrap {
    min-height: 50px;
    margin-bottom: 0;
}
.radiobtn_wrap:not(:last-child) {
    margin-right: 30px
}
.radiobtn_wrap input[type='radio'] {
    display: none;
}
.radiobtn_wrap input[type="radio"] + label{
    position: relative;
    display: inline-block;
    padding-left: 26px;
    cursor: pointer;
    line-height: 16px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
.radiobtn_wrap input[type="radio"] + label:before,
.radiobtn_wrap input[type="radio"] + label:after{
    content: '';
    position: absolute;
    top: -2px;
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transition: all .3s ease;
    border: 1px solid #8F8F9D;
}
.radiobtn_wrap input[type="radio"] + label:before {
    width: 10px;
    height: 10px;
    border: none;
    top:2px;
    left: 4px;
}
.radiobtn_wrap input[type="radio"]:checked + label:before {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    background: #E95420;
}
.radiobtn_wrap input[type="radio"]:checked + label:after {
    border-color: #E95420;
}

.radiobtn_wrap label {
    text-transform: capitalize;
}

.upload_file_wrap {
    border: 1px solid #8F8F9D;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    cursor: pointer;
    min-width: 111px;
    padding: 12px 14px;
}

.upload_file_wrap > div {
    margin-right: 10px;
    width: 16px;
    height: 13px;
}

.login_wrap {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login_wrap button svg {
    width: 48px;
    height: 48px;
}

.login_wrap button > div {
    display: inline-block;
    margin-right: 10px;
}

.login_wrap button {
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 10px 30px 10px 10px;
    font-size: 24px;
    background: #f5f5f5;
    font-weight: 500;
    border-radius: 10px;
    box-shadow: 5px 5px 1px rgba(0,0,0,0.2);
    transition: all .3s;
}

.login_wrap button:hover {
    box-shadow: 0px 0px 1px rgba(0,0,0,0.2);
}
.registration_form .chakra-checkbox__control[data-checked],
.registration_form .chakra-checkbox__control[data-checked][data-hover] {
    background: #000;
    border-color: #000;
}
.registration_form .chakra-checkbox__control {
    border: 1px solid #8F8F9D;
}
.registration_form select {
    border: 1px solid #8F8F9D;
    outline: none;
    border-radius: 0;
    padding: 0 40px 0 12px;
    box-shadow: none;
    height: 50px;
}
.registration_form select:focus {
    outline: none;
    box-shadow: none;
}
.registration_form select:hover,
.registration_form select:focus-visible {
    border: 1px solid #8F8F9D;
}
.registration_form .chakra-select__wrapper {
    margin-bottom: 15px;
}
.registration_form .chakra-checkbox__control svg {
    font-size: 10px;
}
.registration_form p {
    display: block;
    width: 100%;
    margin: 5px 0 0 0;
}
.registration_form .chakra-stack {
    flex-wrap: wrap;
}
.registration_form .css-kq16bc-control {
    border-color: #8F8F9D;
    box-shadow: none;
}
.registration_form .css-kq16bc-control:hover {
    border-color: #8F8F9D;
}
.registration_form .css-7nlflm-control:hover {
    border-color: #8F8F9D;
}
input#upload_file {
    display: none;
}

.small {
    text-transform: none !important;
}

.captial {
    text-transform: capitalize !important;
}


.confrim-checkbox input[type="checkbox"] {
    appearance: none;
    width: 14px;
    height: 14px;
    border: 1px solid;
    border-radius: 0.125rem;
    margin-right: 10px;
    outline: none;
    position: relative;
}
.confrim-checkbox input[type="checkbox"]:checked {
    border-color: #000;
}
.confrim-checkbox input[type="checkbox"]:checked:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
}
.confrim-checkbox input[type="checkbox"]:checked:after {
    content: "";
    position: absolute;
    left: 4px;
    right: 0;
    top: 0;
    bottom: 0;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    transform: rotate(45deg);
    height: 8px;
    width: 3px;
}


.registration_form .chakra-radio__control[data-checked],
.registration_form .chakra-radio__control[data-checked]:hover {
    background: #000;
    border-color: #000;
}
.registration_form label.chakra-text {
    line-height: normal;
    margin-bottom: 5px;
    display: block;
    white-space: nowrap;
    /* overflow: hidden;
    text-overflow: ellipsis; */
}
.registration_form .gender_radio_btn #gender {
    height: 50px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.registration_form .main_select_dropdown {
    width: 115px;
}
.registration_form .phone_number {
    width: calc(100% - 120px);
    margin-left: 5px;
}
.registration_form .gender_radio_btn #gender label {
    margin: 0;
}
.registration_form .main_select_dropdown .select-dropdown > div > div {
    box-shadow: none;
}
.registration_form .main_select_dropdown .select-dropdown > div > div:hover {
    border-color: #8F8F9D;
}
.registration_form .country_list__option.css-d7l1ni-option {
    background: #000;
    color: #fff;
}
.registration_form .country_list__option.css-tr4s17-option {
    background: #000;
    color: #fff;
}
.table-body{
    text-transform: capitalize;
}
.table-body .normal-text{
    text-transform: none;
}
.registration_form .checkbox.from-checkbox { margin-right: 16px; margin-top: 8px; }
.registration_form .input:focus {
    outline: #000;
  }
.registration_form .instrument-field {
    margin-top: 16px;
}

@media (max-width: 1280px) {
    .registration_form label.chakra-text {
        white-space: normal
    }
}

@media only screen and (max-width: 991px) {
    .registration_form form > div {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-gap: 0;
    }   
    .registration_form form > div > div {
        margin-bottom: 15px;
    }
    .registration_form .gender_radio_btn #gender {
        height: auto;
    }
}

@media only screen and (max-width: 575px) {
    .registration_form .main_select_dropdown {
        width: 100%;
    }
    .registration_form .phone_number {
        width: 100%;
        margin-top: 16px;
        margin-left: 0;
    }
}

@media only screen and (max-width: 479px) {
    .registration_form label.chakra-text {
        font-size: 14px;
    }
}
  

/* page styles 8-2-24 */
.banner_content_col {
    width: 40%;
    padding-left: 76px;
    padding-right: 111px;
}
.banner_img_col {
    width: 60%;
    border-left: 1px solid #5C5C5C;
}
.banner_img {
    height: 523px;   
}
.banner_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.banner_content_col h3 {
    font-size: 24px;
    font-weight: 400;
    font-family: 'Optima LT Pro';   
    color: #F60068;
}
.banner_content_col h1 {
    font-family: 'Optima LT Pro'; 
    font-size: 64px;
    font-weight: 400;
    line-height: 78px;
    text-align: left;
}
.banner_content_col p {
    font-size: 18px;
    font-weight: 300;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
}
.banner_content_col p span {
    color: #F60068;
    font-weight: 400;
}
.marquee_wrapper {
    position: relative;
    padding: 25px 0;
    overflow: hidden;
}
.marquee_wrapper div {
    line-height: 0;
}
.marquee_wrapper ul{
    display: inline-flex;
    align-items: center;
}
.marquee_wrapper ul li {
    display: flex;
    align-items: center;
    font-family: 'Optima LT Pro'; 
    color: #fff;
    font-size: 34px;
    line-height: 41.21px;
    margin-top: 0 !important;
    margin-right: 26px;
}
.marquee_wrapper ul li img {
    margin-right: 22px;
    margin-left: 22px;
}
.icon_box p{
    font-family: 'Optima LT Pro'; 
    font-size: 33px;
    font-weight: 400;
    line-height: 40px;
}
.icon_box.border_right {
    border-right: 1px solid #5C5C5C;
}
.icon_box:first-child {
    padding-left: 76px;
    padding-right: 91px;
}
.borderBottom {
    border-bottom: 1px solid #5C5C5C;
}
.form_header_wrapper h2 {
    font-family: 'Optima LT Pro'; 
    font-size: 57px;
    font-weight: 400;
    line-height: 69px;
    text-align: center;
    color: #000;
}
.form_header_wrapper {
    border-top: 1px solid #5C5C5C;
    border-bottom: 1px solid #5C5C5C;
}
.form_row {
    display: flex;
    flex-wrap: wrap;
    padding: 56px 109px;
}
.form_row h3 {
    font-size: 25px;
    font-weight: 400;
    line-height: 35px;
    margin-bottom: 17px;
    display: flex;
}   
.form_data_col label {
    font-size: 19px;
    font-weight: 500;
    line-height: 27px;
    margin-bottom: 5px;
    color: #000;
    font-family: 'Futura LT Pro Medium';
    align-items: flex-start;
}
.form_data_col label .chakra-radio__label {
    font-family: 'Futura LT Pro Light';
}
.form_img_col {
    width: 371px;
}
.form_img_col .form-image-1 {
    height: 678px;
}
.form_data_col {
    width: calc(100% - 490px);
    margin-left: 119px;
}
.borber_bottom {
    position: relative;
}
.borber_bottom:after {
    content: '';
    position: absolute;
    width: 200vw;
    height: 1px;
    background: #000;
    left: -100vw;
    bottom: 0;
}
.form_data_col .upload_file_wrap {
    border: 1px solid #8F8F9D;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    cursor: pointer;
    min-width: 111px;
    padding: 12px 14px;
    font-family: 'Futura LT Pro Light';
}
.form_data_col .upload_file_wrap > div {
    margin-right: 10px;
    width: 16px;
    height: 13px;
}
.banner_heading {
    padding-top: 60px;
}
.checkbox.from-checkbox .chakra-checkbox__label{
    font-family: 'Futura LT Pro Light';
}
.registration_form .chakra-checkbox__control {
    border: 1px solid #000;
    top: 4px;
    position: relative;
}
.registration_form .chakra-stack .chakra-radio {
    align-items: center;
}
@keyframes marquee {
    0% { left: 0; }
    100% { left: -220%; }
}
@media (max-width: 1701px) {
    .banner_img {
        height: 548px;
    }
}
@media (max-width: 1511px) {
    .banner_img {
        height: 573px;
    }
}
@media (max-width: 1480px) {
    .form_row {
        padding: 56px 70px;
    }
}
@media (max-width: 1355px) {
    .banner_img {
        height: 598px;
    }
}
@media (max-width: 1339px){
    .banner_img {
        height: 628px;
    }
}
@media (max-width: 1320px){
    .banner_img {
        height: 701px;
    }
}
@media (max-width: 1280px) {
    .form_row {
        padding: 56px 40px;
    }
}
@media (max-width: 1200px) {
    .form_data_col {
        width: calc(100% - 400px);
        margin-left: 29px;
    }
}
@media (max-width: 1180px) {
    .banner_content_col {
        padding-left: 36px;
    }
    .icon_box:first-child {
        padding-left: 36px;
    }
    .banner_img {
        height: 600px;
    }
}
@media (max-width: 1153px) {
    .banner_img {
        height: 711px;
    }
}
@media (max-width: 1100px) {
    .banner_content_col {
        padding-right: 56px;
    }
}
@media (max-width: 1280px) {
    .banner_content_col {
        padding-right: 76px;
    }
}
@media(max-width: 1024px) {
    .banner_img {
        height: 553px;
    }
    .banner_heading {
        padding-top: 20px;
    }
    .banner_content_col {
        width: 100%;
    }
    .banner_img_col {
        width: 100%;
        margin-top: 20px;
        border-left: none;
        border-top: 1px solid #000;
    }
    .marquee_wrapper {
        padding: 20px 0;
    }
    .marquee_wrapper ul li {
        font-size: 26px;
        line-height: 30px;
    }
    
    .marquee_wrapper div {
        width: 400%;
    }
    .form_img_col {
        width: 100%;
    }
    .form_data_col {
        width: 100%;
        margin-left: 0;
    }
    .form_img_col img {
        display: none;
    }
    .icon_box:first-child {
        padding-left: 40px;
        padding-right: 20px;
    }
    .icon_box:nth-child(2) {
        padding-left: 20px;
    }
    .icon_box:last-child {
        padding-left: 20px;
        padding-right: 40px;
    }
    .icon_box p {
        font-size: 30px;
        line-height: 37px;
    }
}
@media (max-width: 767px) {
    .banner_content_col {
        padding-left: 20px;
        padding-right: 20px;
    }
    .banner_content_col h3 {
        font-size: 20px;
    }
    .banner_content_col h1 {
        font-size: 52px;
        line-height: 66px;
    }
    .banner_content_col p {
        font-size: 16px;
        line-height: 23px;
    }
    .icon_box {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    .icon_box.border_right {
        border-right: none;
        border-bottom: 1px solid #000;
    }
    .icon_box p {
        font-size: 28px;
        line-height: 35px;
    }
    .form_header_wrapper {
        padding-top: 24px !important;
        padding-bottom: 24px !important;
    }
    .form_header_wrapper h2 {
        font-size: 37px;
        line-height: 49px;
    }
    .form_row {
        padding: 30px;
    }
    .form_row h3 {
        font-size: 20px;
        line-height: 30px;
    }
    .icon_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .icon_box svg {
        margin: 0 auto;
    }
    .form_data_col label .chakra-radio__label {
        font-size: 14px;
    }
    .form_data_col label {
        font-size: 14px;
    }
    .checkbox.from-checkbox .chakra-checkbox__label {
        font-size: 14px;
        font-family: 'Futura LT Pro Light';
    }
}
@media (min-width: 576px) {
    .registration_form label.chakra-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }   
}