.header {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 9;
}
.header .logo {
    width: 100%;
    text-align: center;
    padding: 24px 2.5em;
}
.header .logo a {
    display: inline-block;
}
.header .logo a img {
    max-width: 158px;
}
.header .nav-menu-main {
    width: 100%;
    border-bottom: 1px solid #5C5C5C;
    display: flex;
    flex-wrap: wrap;
}
.header .nav-menu-main .main-menu ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.header .nav-menu-main .main-menu ul li {
    margin: 0;
}
.header .nav-menu-main .left-part {
    border-top: 1px solid #5C5C5C;
    width: 34%;
    padding: 17px 1.25VW;
    padding-left: 76px;
    display: flex;
    /* justify-content: center; */
}

.header .nav-menu-main .right-part {
    border-top: 1px solid #5C5C5C;
    width: 34%;
    padding: 17px 1.25VW;
    padding-right: 76px;
    display: flex;
    justify-content: end;
}
.header .nav-menu-main .main-menu ul li {
    padding: 0 35px;
}
.header .nav-menu-main .main-menu ul li:first-child {
    padding-left: 0;
}
.header .nav-menu-main .main-menu ul li:last-child {
    padding-right: 0;
}
.header .nav-menu-main .main-menu ul li a {
    padding-left: 0;
    padding-right: 0;
    font-family: 'Futura LT Pro Medium';
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.1em;
    text-transform: lowercase;
    overflow: hidden;
    position: relative;
    display: block;
}
.header .nav-menu-main .main-menu ul li a:before {
    content: '';
    position: absolute;
    bottom: 0;
    background: #F70068;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    transition: 0.5s all;
}
.header .nav-menu-main .main-menu ul li a:after {
    content: '';
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    transition: 0.5s all;
}
.header .nav-menu-main .main-menu ul li a:hover:before {
    left: 0;
    transition: 0.5s all;
    background: #F70068;
}
.header .nav-menu-main .main-menu ul li a:hover:after {
    left: 100%;
    transition: 0.5s all;
    background: #F70068;
}
.header .nav-menu-main .right-part {
    width: 66%;
    border-top: 1px solid #5C5C5C;
    border-left: 1px solid #5C5C5C;
}

@media only screen and (max-width: 1500px) {
    .header .nav-menu-main .main-menu ul li {
        padding: 0 15px;
    }
    .header .nav-menu-main .main-menu ul li a {
        font-size: 18px;
        line-height: 28px;
    }
}

@media only screen and (max-width: 1180px) {
    .header .nav-menu-main .left-part {
        padding-left: 36px;
        padding-right: 10px;
    }
}
@media only screen and (max-width: 1024px) {
    .header .nav-menu-main .left-part {
        width: 100%;
    }
    .header .nav-menu-main .main-menu ul li a {
        font-size: 16px;
        line-height: 26px;
    }
    .header .nav-menu-main .right-part {
        display: none;
    }
}
@media only screen and (max-width: 767px) {
    .header .nav-menu-main .left-part {
        justify-content: center;
    }
}