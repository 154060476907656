.footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-top: 1px solid #1E1E1E;
}
.footer .footer-left {
    padding: 4.166666666666666VW;
    width: 50%;
    display: flex;
    align-items: flex-start;
}
.footer .footer-left .footer-logo {
    padding: 0 4.166666666666666VW;
    width: 46%;
}
.footer .footer-left .footer-logo a {
    display: inline-block;
}
.footer .footer-left .footer-logo a img {
    max-width: 199px;
}
.footer .footer-left .footer-menu {
    width: 54%;
    padding: 0 0 0 4.166666666666666VW;
}
.footer .footer-right .footer-menu.mobile {
    display: none;
}
.footer .footer-left .footer-menu ul {
    margin: 0 0 2.083333333333333VW 0;
}
.footer .footer-left .footer-menu ul li {
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 0.833vw;
    line-height: 1.563vw;
}
.footer .footer-left .footer-menu ul li a {
    position: relative;
    display: inline-block;
    overflow: hidden;
    font-family: 'Futura LT Pro Medium';
    /* font-size: 20px; */
    font-size: 1.0416666666666665VW;
    line-height: 1;
}
.footer .footer-left .footer-menu ul li a:before {
    content: '';
    position: absolute;
    bottom: 0;
    background: #F70068;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    transition: 0.5s all;
}
.footer .footer-left .footer-menu ul li a:after {
    content: '';
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    transition: 0.5s all;
}
.footer .footer-left .footer-menu ul li a:hover:before {
    left: 0;
    transition: 0.5s all;
    background: #F70068;
}
.footer .footer-left .footer-menu ul li a:hover:after {
    left: 100%;
    transition: 0.5s all;
}
.footer .footer-left .footer-menu p {
    font-size: 0.9375VW;
    font-weight: 300;
    line-height: 28px;
}
.footer .footer-left .copyright-text {
    letter-spacing: 0.1em;
}
.footer .footer-right {
    width: 50%;
    list-style: none;
    border-left: 1px solid #1E1E1E;
    padding: 4.166666666666666VW;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
}
.footer .footer-right h3 {
    font-size: 2.500vw;
    line-height: 1.15;
    font-family: 'Optima LT Pro';
    font-weight: 400;
}
.footer .footer-right h3 a {
    color: #F70068;
}
.footer .footer-right ul {
    display: flex;
    flex-wrap: wrap;
}
.footer .footer-right ul li {
    padding-right: 4.167vw;
}
.footer .footer-right ul li a {
    font-size: 1.667vw;
    font-weight: 300;
    /* line-height: 2.500vw; */
    line-height: 1.3;
    position: relative;
    overflow: hidden;
    display: inline-block;
}
/*  */
.footer .footer-right ul li a:before {
    content: '';
    position: absolute;
    bottom: 0;
    background: #F70068;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    transition: 0.5s all;
}
.footer .footer-right ul li a:after {
    content: '';
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    transition: 0.5s all;
}
.footer .footer-right ul li a:hover:before {
    left: 0;
    transition: 0.5s all;
    background: #F70068;
}
.footer .footer-right ul li a:hover:after {
    left: 100%;
    transition: 0.5s all;
}

@media only screen and (max-width: 1500px) {
    .footer .footer-left .footer-menu ul li a {
        font-size: 18px;
        line-height: 26px;
    }
}

@media only screen and (max-width: 1024px) {
    footer.site-footer .footer-left .footer-menus p, .footer .footer-left .footer-menu p {
        font-size: 12px;
        line-height: 24px;
    }
    .footer .footer-left .footer-menu ul li a {
        font-size: 16px;
    }
}

@media only screen and (max-width: 991px) {
    .footer .footer-left {
        padding: 30px;
    }
    .footer .footer-right {
        padding: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .footer .footer-right {
        width: 100%;
        order: 1;
        padding: 0;
        border-left: 0;
    }
    .footer .footer-right h3 {
        font-size: 32px;
        line-height: 48px;
        border-bottom: 1px solid #1E1E1E;
        padding: 80px 18px;
        border-bottom: 1px solid #1E1E1E;
    }
    .footer .footer-right ul li {
        width: 100%;
        margin: 0 0 26px 0;
    }
    .footer .footer-right ul li a {
        font-size: 22px;
        line-height: 34px;
        padding: 0;
    }
    .footer .footer-right ul li:last-child {
        margin: 0;
    }
    .footer .footer-right ul.social-media-ul {
        width: 50%;
        padding: 80px 18px;
    }
    .footer .footer-left {
        flex-wrap: wrap;
        width: 100%;
        order: 2;
        padding: 40px 0;
        border-top: 1px solid #1E1E1E;
    }
    .footer .footer-left .footer-menu ul {
        display: none;
    }
    .footer .footer-left .footer-menu ul li {
        margin: 0 0 8px;
    }
    .footer .footer-left .footer-menu ul li a {
        font-size: 18px;
        line-height: 26px;
        font-family: 'Futura LT Pro Medium';
    }
    .footer .footer-left .footer-logo {
        width: 100%;
        padding: 0;
        text-align: center;
    }
    .footer .footer-left .footer-logo a img {
        max-width: 140px;
    }
    .footer .footer-left .footer-menu {
        padding: 0px;
        width: 100%;
    }
    .footer .footer-left .footer-menu p.copyright-text {
        margin: 40px 0 0;
        font-size: 18px;
        line-height: 36px;
        text-align: center;
        justify-content: center;
    }
    .footer .footer-right .footer-menu.mobile {
        display: block;
        width: 50%;
        padding: 80px 18px;
        border-left: 1px solid #1E1E1E;
    }
    .footer .footer-right .footer-menu.mobile ul li {
        margin: 0 0 8px;
    }
    .footer .footer-right .footer-menu.mobile ul li a {
        font-family: 'Futura LT Pro Medium';
        font-size: 18px;
        line-height: 26px;
    }
}