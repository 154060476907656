.dashboard_header {
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}
.dashboard_header .search {
    width: 100%;
    padding: 0 15px;
    max-width: 767px;
}
.dashboard_header .search .chakra-input__group {
    border-radius: 2px;
}
.dashboard_header .search svg {
    color: #000;
}
.dashboard_header .header_icons .icon_list {
    display: flex;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
}
.dashboard_header .header_icons .icon_list:first-child {
    margin-left: 0;
}
.dashboard_header .header_icons .action_icon {
    margin-left: 5px;
}
.dashboard_header .header_icons .action_icon svg {
    width: 26px;
    height: 26px;
    cursor: pointer;
}
.dashboard_header .header_icons .refresh_icon svg {
    width: 20px;
    height: 20px;
}
.dashboard_header .header_icons .refresh_icon svg path {
    fill: #000;
}
.dashboard_header .header_icons .icon_list p {
    margin-left: 5px;
    margin-top: 3px;
}    
.filter_checkbox {
    background: #000;
    padding: 15px !important;
    text-align: center;
}
.filter_checkbox .filter_list {
    width: 100%;
    line-height: 0;
}
.filter_checkbox .filter_list {
    width: 100%;
    line-height: 0;
    display: flex;
    justify-content: center;
}
.filter_checkbox label {
    color: #fff;
    width: auto;
    display: inline-block;
}
.filter_checkbox .checkbox {
    margin-right: 15px;
}
.filter_checkbox .checkbox:last-child {
    margin-right: 0;
}
.filter_checkbox .chakra-checkbox__control[data-checked],
.filter_checkbox .chakra-checkbox__control[data-checked][data-hover] {
    background: #fff;
    border-color: #fff;
}
.filter_checkbox .chakra-checkbox__control {
    border: 1px solid #fff;
    width: 14px;
    height: 14px;
}
.filter_checkbox .chakra-checkbox__control svg polyline {
    stroke: #000;
}
.filter_checkbox label .chakra-checkbox__label {
    line-height: 1;
    margin-top: 2px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
}
.dashboard_table button.chakra-button {
    height: auto;
    padding: 0;
}
.dashboard_table button.chakra-button svg {
    font-size: 18px;
}
.dashboard_table span.chakra-checkbox__control[data-checked],
.dashboard_table span.chakra-checkbox__control[data-checked][data-hover] {
    background: #000;
    border-color: #000;
}
.dashboard_table span.chakra-checkbox__control {
    border: 1px solid #000;
    width: 14px;
    height: 14px;
}
.dashboard_table span.chakra-checkbox__control svg {
    font-size: 8px;
}
.dashboard_table .prev_next_buttons {
    justify-content: flex-end;
    padding: 30px;
}
.dashboard_table .prev_next_buttons button.chakra-button {
    border-radius: 100px;
    width: 40px;
    height: 40px;
    background: #000;
}
.dashboard_table .prev_next_buttons button.chakra-button svg {
    font-size: 28px;
}
.dashboard_table .prev_next_buttons button.chakra-button svg path {
    fill: #fff;
}
.dashboard_table .prev_next_buttons button.chakra-button:hover {
    opacity: 0.8;
}

/* Modal */
.chakra-modal__body h2 {
    font-size: 28px;
}
header.chakra-modal__header {
    text-align: center;
    padding: 15px 0;
    font-size: 26px;
}
.chakra-modal__body {
    padding-top: 24px !important;
}
.chakra-modal__body ul.user_data_list {
    padding-bottom: 20px;
}
.chakra-modal__body ol {
    padding-left: 7px;
}
.chakra-modal__body ol li {
    margin: 0 0 5px 0;
}
.chakra-modal__footer button.chakra-button {
    background: #000;
    color: #fff;
    width: 100%;
}
.chakra-modal__footer button.chakra-button:hover {
    opacity: 0.8;
}
.chakra-modal__footer button.chakra-button.previous {
    width: auto;
    font-size: 0;
    position: absolute;
    top: calc(50% - 60px);
    background: #fff;
    left: 34px;
    border-radius: 100px;
}
.chakra-modal__footer button.chakra-button.previous:before {
    content: "";
    display: block;
    margin: auto;
    width: 13px;
    height: 13px;
    border-top: 2px solid #000;
    border-left: 2px solid #000;
    position: absolute;
    transform: rotate(-40deg);
    left: 7px;
    right: 0;
}
.chakra-modal__footer button.chakra-button.next {
    width: auto;
    font-size: 0;
    position: absolute;
    top: calc(50% - 60px);
    background: #fff;
    right: 34px;
    border-radius: 100px;
}
.chakra-modal__footer button.chakra-button.next:before {
    content: "";
    display: block;
    margin: auto;
    width: 13px;
    height: 13px;
    border-top: 2px solid #000;
    border-left: 2px solid #000;
    position: absolute;
    transform: rotate(130deg);
    left: 0;
    right: 7px;
}
.no-data-found {
    padding: 10px;
    font-size: 26px;
    font-weight: bold;
}