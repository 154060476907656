.success-title{
    font-size: 48px;
}

@media only screen and (max-width: 1500px) {
    .success-title{
        font-size: 48px;
    }
}

@media only screen and (max-width: 1024px) {
    .success-title{
        font-size: 40px;
    }
}

@media only screen and (max-width: 991px) {
    .success-title{
        font-size: 35px;
    }
}

@media only screen and (max-width: 767px) {
    .success-title{
        font-size: 30px;
    }
}